<template>
  <transition name="modal" class="modal" :data-test-id="elementTest">
    <div v-if="showModal" class="modal-mask" :class="{ fullscreen }" :data-test-id="elementTest + '-mask'">
      <div class="modal-wrapper" :data-test-id="elementTest + '-modal-wrapper'" @click="close">
        <div
          class="modal-container"
          :data-test-id="elementTest + '-container'"
          :class="position"
          :style="`max-width: ${maxWidth}; min-width: ${minWidth}`"
          @click.stop
        >
          <div v-if="$slots.title" class="modal-header" :data-test-id="elementTest + '-header'">
            <h6 class="modal-header-title" :data-test-id="elementTest + '-header-title'">
              <slot name="title" />
            </h6>
            <div v-if="showCloseButton" class="modal-close" @click="close">
              <div class="icon-close u-bg-dark-blue" alt="close" />
            </div>
          </div>
          <div class="modal-body" :data-test-id="elementTest + '-body'">
            <slot name="content" />
          </div>
          <div class="modal-footer" :data-test-id="elementTest + '-footer'">
            <slot name="options" />
            <slot name="cancel-button" />
            <slot name="confirm-button" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'StandardModal',
  props: {
    maxWidth: { type: String, default: '320px' },
    minWidth: { type: String, default: '0px' },
    name: { type: String, default: 'modal' },
    showCloseButton: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: true },
    position: { type: String, default: 'c' }, // could be c for center, tr, tl, br, bl for topright, etc
    elementTest: { type: String, default: '' },
  },
  emits: ['close', 'send-payload'],
  data() {
    return {
      showModal: false,
      payload: {},
      attr: [
        { key: '.topbar', index: 0 },
        { key: '.navbar-left', index: 0 },
        { key: '.layout-content', index: 0 },
        { key: '.search-autocomplete-content', index: 1 },
        { key: 'footer', index: -1 },
      ],
      modalOpenName: 'modalOpen',
      modalCloseName: 'modalClose',
    }
  },
  computed: {
    openedSearchAutocomplete() {
      // Check if search autocomplete is opened for top bar z-index
      return !!document.querySelector('.search-autocomplete-content')
    },
  },
  mounted() {
    this.setModalEventNames()
    this.$bus.on(this.modalOpenName, data => {
      if (typeof data === 'object') {
        const { name, payload } = data
        if (this.name === name) this.showModal = true
        this.payload = payload
        this.$emit('send-payload', this.payload)
      } else if (data === this.name) this.showModal = true
      // z-index updated to have the mask overlay over the header
      this.attr.forEach(attr => {
        const element = document.querySelector(attr.key)
        if (element) {
          if (attr.key === '.topbar') {
            element.setAttribute('style', `z-index: ${this.openedSearchAutocomplete ? '1' : '0'};`)
          } else element.setAttribute('style', `z-index: ${attr.index};`)
        }
      })
    })
    // Always call modalClose before delete modal
    // z-index re-set to initial value
    this.$bus.on(this.modalCloseName, name => {
      if (name === this.name) this.showModal = false
      this.refreshZIndex()
    })
  },
  beforeUnmount() {
    this.setModalEventNames()
    this.$bus.off(this.modalOpenName)
    this.$bus.off(this.modalCloseName)
    this.refreshZIndex()
  },
  methods: {
    close() {
      this.$bus.emit(this.modalCloseName, this.name)
      this.$emit('close')
    },
    refreshZIndex() {
      this.attr.forEach(attr => {
        const element = document.querySelector(attr.key)
        if (element) element.setAttribute('style', '')
      })
    },
    setModalEventNames() {
      // Set separate modal events to avoïd global $on/$off on standard modals when navigate
      this.modalOpenName = this.name !== 'modal' ? 'modalOpen_' + this.name : 'modalOpen'
      this.modalCloseName = this.name !== 'modal' ? 'modalClose_' + this.name : 'modalClose'
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  &.fullscreen {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .modal-container {
      width: fit-content;
      padding: $spacing-md;
      background-color: var(--white);
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      &.c {
        margin: 0px auto;
      }
      &.tr {
        position: fixed;
        right: $spacing-md;
        top: $spacing-md;
        @include mq($mq-sm) {
          right: $spacing-sm;
        }
      }
      &.tl {
        left: $spacing-md;
        position: fixed;
        top: $spacing-md;
        @include mq($mq-sm) {
          left: $spacing-sm;
        }
      }
      &.br {
        bottom: $spacing-md;
        position: fixed;
        right: $spacing-md;
        @include mq($mq-sm) {
          right: $spacing-sm;
        }
      }
      &.bl {
        bottom: $spacing-md;
        left: $spacing-md;
        position: fixed;
        @include mq($mq-sm) {
          left: $spacing-sm;
        }
      }
      .modal-header {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: $spacing-md;
        .modal-header-title {
          margin: 0;
          font-size: 1.3rem;
          font-family: Avenir;
          font-weight: 700;
          color: var(--night-blue);
        }
      }
      .modal-body {
        margin: $spacing-md 0;
      }
      .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: $spacing-sm;
        text-align: right;
      }
    }
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@include mq($mq-md) {
  .modal-container {
    width: calc(100% - 20px) !important;
  }
}
.modal-close > i {
  align-self: center;
}
.modal-close {
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
</style>

<style lang="scss">
.modal-btn-wrapper {
  justify-content: flex-end;
  margin-top: $spacing-lg;
}
</style>
